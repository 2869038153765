<template>
<div>
   <van-cell-group>
      <van-cell title="活动主题" :value="Info.activity_name" />
      <van-cell title="支付金额" :value="Info.amount==0?'免费':Info.amount"  value-class="text-theme3"/>
	  <van-cell title="支付状态" :value="Info.pay_status_note"  value-class="text-theme3"/>
      <van-cell title="姓名" :value="Info.linkman" />
      <van-cell title="手机" :value="Info.phone" />
      <van-cell title="报名时间" :value="Info.createtime" />
      <van-cell title="查看电子票" value="内容"  title-class="text-theme2" is-link :to="{path:'/activity/enroll/ticket',query:{id:Info.id}}" v-if="Info.pay_status > 0"/>
      <van-cell title="查看更多信息" value="内容"  title-class="text-theme2" is-link :to="{path:'/activity/enroll/moremessage',query:{id:Info.id}}"/>

    </van-cell-group>
	
	<div style="padding: 0.5rem" v-if="Info.is_free!=true&&Info.pay_status==0">
	    <van-button type="info" class="mr10" block @click="toPay">去支付</van-button>
	</div>
</div>
  
</template>

<script>
import { Cell, CellGroup, Button} from 'vant';
import {get_ticket_detail,activity_enroll_message} from '@/request/api';
export default {
    components:{
       [Cell.name]:Cell,
       [CellGroup.name]:CellGroup,
	   [Button.name]:Button
    },   
   data(){
       return{
          activity_id:'',
          activity_name:'',
          Info:{
              id:11,
              linkname:'测试数据',
              money:'￥1',
              phone:'1546654654',
              createtime:1578049156,
              sell_id:'1号',
              get_id:'1号'


          }
       }
   },
   mounted:function(){
        this.activity_id = this.$route.query.id;
        this.activity_name = this.$route.query.name;
        this.loadData();
   },
   methods:{
        loadData(){
            this.showLoading('加载中',true)
            activity_enroll_message({
                activity_id:this.activity_id
            }).then(res=>{
                this.hideLoading();
                if(res.code==1){
                     this.Info = res.data;
                }else{
                    this.$dialog({message:res.msg});
                }

            }).catch(err=>{
                 this.hideLoading();
                this.$dialog({message:'获取报名信息出现错误，请重新刷新页面'})
            })

        },
		toPay(){
		    this.$router.push({path: "/activity/enroll/pay",query:{ticket_id:this.Info.id,card_name:this.Info.card_info.card_name}});
		}
   }
}
</script>

<style>

</style>